@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Montserrat", sans-serif;
}

.app {
  position: relative;
}
html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: auto;
  min-height: 100vh;
}

.header {
	padding: 20px 50px;
	background-color: #1ca7ec;
	color: white;
	display: -webkit-flex;
	display: flex;
	margin-bottom: 20px;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.header__logo {
	width: 200px;
	object-fit: contain;
}
.company__name {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	margin-left: -50px;
}
/* 
h1 {
	margin-block-start: 0em;
	margin-block-end: 0em;
} */

@media screen and (max-width: 768px) {
	.header__logo {
		display: -webkit-flex;
		display: flex;
		font-size: 10px;
	}
	.company__name {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		font-size: 16px;
		margin-left: 0px;
	}
}

.user {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	width: 60vw;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	border: 10px solid lightgray;
	border-radius: 10px;
	padding: 10px;
}

.user h2,
h3 {
	text-align: left;
}

.chat {
	padding: 0px 50px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-left: auto;
	margin-right: 30px;
	max-width: 800px;
	border: 1px solid lightgray;
	box-shadow: 0px 0px 10px rgba(94, 90, 90, 0.8);
	border-radius: 20px;
	overflow: scroll;
	max-height: 500px;
	-webkit-flex: 0.9 1;
	        flex: 0.9 1;
}

.left {
	position: relative;
	border-radius: 10px;
	display: block;
	max-width: 400px;
	margin-right: auto;
	margin-bottom: 20px;
	background: linear-gradient(to right, #1ca7ec, #7bd5f5);
	color: white;
	font-size: 1rem;
	text-align: left;
	padding: 10px 15px;
}

.dropShadow {
	-webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.851));
	        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.851));
}

.left:hover {
	box-shadow: 1px 1px 5px 1px rgba(119, 115, 115, 0.707);
}

.left::before {
	content: "";
	position: absolute;
	top: 2px;
	left: 0;
	height: 25px;
	width: 25px;
	object-fit: contain;
	margin-left: -40px;
	border-radius: 50%;
	background: url(/static/media/Avatar-03.80adfd1f.png);
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	border: 1px solid #ddd;
}

.right {
	position: relative;
	display: block;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 1.2rem;
	border-radius: 10px;
	padding: 10px 15px;
	width: auto;
	max-width: 400px;
	margin-left: auto;
	margin-bottom: 20px;
	color: white;
	background: linear-gradient(90deg, #1ca7ec 0, #7ad5f5);
	box-shadow: 1px 1px 5px 1px lightgray;
	-webkit-appearance: initial;
}
.showTime {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	margin-top: -16px;
	margin-bottom: 20px;
	margin-left: auto;
	color: lightslategray;
	font-size: small;
}

.right:hover {
	box-shadow: 1px 1px 5px 1px rgba(119, 115, 115, 0.707);
}

.right::before {
	content: "";
	position: absolute;
	top: 2px;
	right: 0;
	height: 25px;
	width: 25px;
	object-fit: contain;
	margin-right: -40px;
	border-radius: 50%;
	background: url("/static/media/CustomerIcon 1.72da8a00.svg");
	background-position: center center;
	background-size: contain;
	border: 1px solid #ddd;
}

.chatBox {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	width: auto;
	padding: 20px;
}

.loading {
	animation: loader 0.5s infinite alternate-reverse;
	text-align: center;
}

.chatHeading {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
	padding: 20px;
	background-color: #1ca7ec;
	border-radius: 30px;
	color: white;
}

@-webkit-keyframes loader {
	0% {
		opacity: 0;
		color: black;
	}

	100% {
		opacity: 1;
		color: #1ca7ec;
	}

	/* from {
    opacity: 0;
  }

  to
  {
    opacity: 1;
  } */
}

@keyframes loader {
	0% {
		opacity: 0;
		color: black;
	}

	100% {
		opacity: 1;
		color: #1ca7ec;
	}

	/* from {
    opacity: 0;
  }

  to
  {
    opacity: 1;
  } */
}

.chatBox__highlight {
	margin-left: auto;
	margin-right: auto;
	margin-top: 60px;
	border: 2px solid lightgray;
	padding: 20px;
	border-radius: 20px;
	-webkit-animation: highlight 1s infinite linear alternate;
	        animation: highlight 1s infinite linear alternate;
}

@-webkit-keyframes highlight {
	0% {
		border: 2px solid lightgray;
		box-shadow: 1px 1px 5px 2px white;
	}
	100% {
		border: 2px solid black;
		box-shadow: 1px 1px 5px 2px black;
	}
}

@keyframes highlight {
	0% {
		border: 2px solid lightgray;
		box-shadow: 1px 1px 5px 2px white;
	}
	100% {
		border: 2px solid black;
		box-shadow: 1px 1px 5px 2px black;
	}
}

@media screen and (max-width: 768px) {
	.chatBox {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.chat {
		margin-bottom: 20px;
		width: calc(100% - 5vh);
		margin-left: auto;
		margin-right: auto;
	}
	.chatHeading {
		font-size: 20px;
	}
	.chatBox__highlight {
		font-size: 10px;
	}
}

@media screen and (max-width: 420px) {
	.chatBox {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		width: calc(100% - 2px);
		padding: 5px;
	}
	.chat {
		max-height: 400px;
	}
	.left,
	.right {
		font-size: 12px;
	}
	.chat {
		margin-left: auto;
		margin-right: auto;
	}
	.loading {
		font-size: 1rem;
	}
	.chatBox__highlight {
		font-size: 8px;
		text-align: center;
	}
}

.chatTable {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px rgba(94, 90, 90, 0.8);
  border-radius: 20px;
  margin-left: 30px;
  max-height: 500px;
}

.chatTable__data {
  overflow: scroll;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-bottom: 30px;
}

/* .data {
	display: flex;
	flex-direction: row;
} */

.headers {
  font-weight: 800;
}
.tableHeading {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  text-align: center;
}

.tableRow:nth-child(2n + 1) {
  background-color: lightgray;
}

.tableRow {
  height: 50px;
}
.table {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow-y: scroll;
}

.heading {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  /* background-color: wheat; */
  -webkit-justify-content: center;
          justify-content: center;

  -webkit-flex: 1 1;

          flex: 1 1;
}

/* .heading > h3 {
} */

.value {
  margin-right: 20px;
}

th,
td {
  padding: 10px 3px;
  text-align: center;
}

td {
  /* border: 1px solid black; */
  width: 30%;
}
th {
  width: 10%;
  /* border-bottom: 2px solid black; */
}

.tableData {
  width: 100%;
  /* font-weight: ; */
}

tbody {
  width: 100%;
}

.chattableheading {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #1ca7ec;
  border-radius: 30px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  width: 550px;
  max-width: 550px;
}
thead {
  border-bottom: 1px solid black;
}
table {
  border-collapse: collapse;
  overflow: hidden;
}

/* tr {
	border-bottom: 1pt solid black;
  } */

.theadrow {
  color: #111;
  font-size: 24px;
  border-bottom: 1px solid #4e4e4e;
  text-align: center;
}
.tableData td {
  padding: 15px 20px;
  /* text-align: center; */
}
table tr:nth-child(even) {
  background-color: rgb(248, 236, 220, 0.7);
}
.capitalise {
  text-transform: capitalize;
}
.theadrow td {
  font-weight: bold;
}

.user-data-table {
  margin: 0 auto;
}
.user-data-table::-webkit-scrollbar {
  /* display: none; */
  width: 0;
}
@media screen and (max-width: 768px) {
  .chatTable {
    width: calc(100% - 5vh);
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .table {
    width: auto;
    overflow-x: scroll;
    table-layout: auto;
    width: 100%;
  }
  .tableData h3 {
    width: 33%;
    max-width: 33%;
    height: 30px;
    font-size: 12px;
  }
  th,
  td {
    width: 33%;
    max-width: 33%;
    height: 30px;
    font-size: 10px;
    table-layout: auto;
    /* width: 100%; */
  }
  .chattableheading {
    width: 350px;
    font-size: 20px;
  }

  .heading h3 {
    font-size: 13px;
  }
}

@media screen and (max-width: 420px) {
  .chatTable {
    max-height: 400px;
  }
  th,
  td {
    padding: 5px 2px;
  }
}

@media screen and (max-width: 420px) {
  body {
    font-size: 12px;
  }

  .heading > h3 {
    font-size: 12px;
  }
}

.footer-top-section,
.footer-mid-section {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  width: 100%;
  font-family: "Lato";
}
.footer-mid-section {
  width: 65%;
  margin: 20px auto;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  border-width: 0px 0px 0px 0px;
  border-color: #ececed;
  border-style: solid;
}
.footer-top-section > div {
  width: 33%;
}
.footer-top-section > div:nth-child(2) {
  width: 30%;
}
.top-second,
.footer-bottom-section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.footer-bottom-section {
  border-top: 1px solid #eee;
  padding-top: 40px;
  width: 90%;
  margin: 20px auto;
  line-height: 1.5;
  color: #919191;
}

.location-title {
  font-size: 16px;
  line-height: 1.2em !important;
  text-align: center;
  text-transform: uppercase;
  color: #3f3f3f;
}
.location-title {
  /* color: red; */
  font-size: 16px;
}
.location-address {
  font-size: 15px;
  /* line-height: 0.6em !important; */
  text-align: center;
  color: #919191;
}
.location-address p {
  font-size: 15px;
}
.top-second-social-media {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px 5px;
}
.footer-mid-firstimage {
  width: 15%;
}
.footer-mid-secondimage {
  width: 10%;
  height: auto;
}
.footer-mid-thirdimage {
  width: 55%;
}
@media screen and (min-width: 300px) and (max-width: 680px) {
  .section > div {
    width: 100%;
  }

  .footer-mid-section {
    width: 100%;
  }
  .footer-mid-firstimage {
    width: 70%;
    margin: 10px auto;
  }
  .footer-mid-secondimage {
    width: 35%;
    margin: 10px auto;
  }
  .footer-mid-thirdimage {
    width: 85%;
    margin: 10px auto;
  }
  .footer-top-section,
  .footer-mid-section,
  .footer-bottom-section {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    /* line-height: 0.5em; */
    font-size: 10px;
  }
}

