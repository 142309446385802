.app {
  position: relative;
}
html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: auto;
  min-height: 100vh;
}
