.header {
	padding: 20px 50px;
	background-color: #1ca7ec;
	color: white;
	display: flex;
	margin-bottom: 20px;
	align-items: center;
	justify-content: space-between;
}
.header__logo {
	width: 200px;
	object-fit: contain;
}
.company__name {
	display: flex;
	justify-content: space-evenly;
	margin-left: -50px;
}
/* 
h1 {
	margin-block-start: 0em;
	margin-block-end: 0em;
} */

@media screen and (max-width: 768px) {
	.header__logo {
		display: flex;
		font-size: 10px;
	}
	.company__name {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		margin-left: 0px;
	}
}
