.user {
	display: flex;
	flex-direction: column;
	width: 60vw;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	border: 10px solid lightgray;
	border-radius: 10px;
	padding: 10px;
}

.user h2,
h3 {
	text-align: left;
}
