.chatTable {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px rgba(94, 90, 90, 0.8);
  border-radius: 20px;
  margin-left: 30px;
  max-height: 500px;
}

.chatTable__data {
  overflow: scroll;
  flex: 1;
  margin-bottom: 30px;
}

/* .data {
	display: flex;
	flex-direction: row;
} */

.headers {
  font-weight: 800;
}
.tableHeading {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-around;
  text-align: center;
}

.tableRow:nth-child(2n + 1) {
  background-color: lightgray;
}

.tableRow {
  height: 50px;
}
.table {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}

.heading {
  display: flex;
  width: 100%;
  /* background-color: wheat; */
  justify-content: center;

  flex: 1;
}

/* .heading > h3 {
} */

.value {
  margin-right: 20px;
}

th,
td {
  padding: 10px 3px;
  text-align: center;
}

td {
  /* border: 1px solid black; */
  width: 30%;
}
th {
  width: 10%;
  /* border-bottom: 2px solid black; */
}

.tableData {
  width: 100%;
  /* font-weight: ; */
}

tbody {
  width: 100%;
}

.chattableheading {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #1ca7ec;
  border-radius: 30px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  width: 550px;
  max-width: 550px;
}
thead {
  border-bottom: 1px solid black;
}
table {
  border-collapse: collapse;
  overflow: hidden;
}

/* tr {
	border-bottom: 1pt solid black;
  } */

.theadrow {
  color: #111;
  font-size: 24px;
  border-bottom: 1px solid #4e4e4e;
  text-align: center;
}
.tableData td {
  padding: 15px 20px;
  /* text-align: center; */
}
table tr:nth-child(even) {
  background-color: rgb(248, 236, 220, 0.7);
}
.capitalise {
  text-transform: capitalize;
}
.theadrow td {
  font-weight: bold;
}

.user-data-table {
  margin: 0 auto;
}
.user-data-table::-webkit-scrollbar {
  /* display: none; */
  width: 0;
}
@media screen and (max-width: 768px) {
  .chatTable {
    width: calc(100% - 5vh);
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .table {
    width: auto;
    overflow-x: scroll;
    table-layout: auto;
    width: 100%;
  }
  .tableData h3 {
    width: 33%;
    max-width: 33%;
    height: 30px;
    font-size: 12px;
  }
  th,
  td {
    width: 33%;
    max-width: 33%;
    height: 30px;
    font-size: 10px;
    table-layout: auto;
    /* width: 100%; */
  }
  .chattableheading {
    width: 350px;
    font-size: 20px;
  }

  .heading h3 {
    font-size: 13px;
  }
}

@media screen and (max-width: 420px) {
  .chatTable {
    max-height: 400px;
  }
  th,
  td {
    padding: 5px 2px;
  }
}

@media screen and (max-width: 420px) {
  body {
    font-size: 12px;
  }

  .heading > h3 {
    font-size: 12px;
  }
}
